import React from "react";
import Cookies from "js-cookie";
import { configuration } from "../config.js";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      axiosGet: "",
    };
  }

  componentDidMount() {
    var status = this.getQueryVariable("status");
    if (status === "done") {
      // we are ending
      this.endSocialLogin();
    } else {
      // we are starting
      this.startSocialLogin();
    }
  }

  startSocialLogin() {
    var port = this.getQueryVariable("port");
    var loginMethod = this.getQueryVariable("loginMethod");

    console.log("Port is:", port);
    Cookies.set("port", port);
    Cookies.set("loginMethod", loginMethod);
    // make axios request set the

    var baseUrl = configuration.serverBaseUrl;

    if (loginMethod === "twitter") {
      window.open(baseUrl + "api/v1/login/twitter/", "_self");
    } else if (loginMethod === "google") {
      window.open(baseUrl + "api/v1/login/google/", "_self");
    } else if (loginMethod === "facebook") {
      window.open(baseUrl + "api/v1/login/facebook/", "_self");
    } else {
      console.log("You need to send the login method you would like to use. ");
    }
  }

  endSocialLogin() {
    var port = Cookies.get("port");

    var encodedJson = Cookies.getJSON("fawkes");
    let buff = new Buffer(encodedJson, "base64");
    let text = buff.toString("ascii");

    var json = JSON.parse(text);
    var returnJson = json.passport.user;
    var stringMessage = JSON.stringify(returnJson);

    var ws = new WebSocket("ws://localhost:" + port + "/SLServer");
    ws.onopen = function () {
      ws.send(stringMessage);
    };
    ws.onmessage = function (evt) {
      var received_msg = evt.data;
      console.log("Received", received_msg);
      if (received_msg === "done") {
        // TODO - Find out how to close the window when the process is done.
      }
    };
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    console.log("Query variable %s not found", variable);
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <h1>Login Page</h1>
        <p>
          You should be able to login with Twitter, Google, and Facebook from
          this page to get authenticated with the app.
        </p>
      </div>
    );
  }
}
export default Login;
