import React from "react";
import './LicenseTestBench.css';

const HttpError = (props) => {
        return (
            <div className="ltb-http-error">
                <p>HTTP {props.error.status}: {props.error.message}</p>
            </div>
        )
}

export default HttpError;