import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import './LicenseTestBench.css';
import Login from './Login.js';
import LicenseList from './LicenseList.js';
import LicenseInfo from './LicenseInfo.js';
import version from './data/version.json'

class LicenseTestBench extends React.Component {
    constructor(props) {
        super(props);
        const myToken = Cookies.get("token");
        const myName = Cookies.get("name");
        const authenticated = (myToken === undefined) ? false : true;
        this.state = {
            version: "0.0.0.0 x",
            commitUrl: "",
            bearerToken: myToken,
            authenticated: authenticated,
            name: myName,
            licenseList: [],
            hardwareId: 0,
            hardwareItems: {},
        };
    }

    componentDidMount() { 
        const versionObj = version.version;
        const { major, minor, build, patch, commit } = versionObj;
        const versionString = `${major}.${minor}.${build}.${patch} ${commit}`;
        this.setState({
            version: versionString,
            commitUrl: 'https://bitbucket.org/fawkesengineeringinc/solutionslink/commits/' + commit
        });
        if (this.state.authenticated) { 
            const hardwareId = Cookies.get('hardwareId');
            const hardwareItems = this.getHardwareItems(hardwareId);
            this.getLicenses();
            

            this.setState({
                hardwareId: Cookies.get('hardwareId'),
                hardwareItems: hardwareItems,
            });
        }
    }

    getLicenses = async () => {
        try {

            this.axiosInstance = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.state.bearerToken
                },
            });
            const response = await this.axiosInstance.get("/api/v1/admin/license-test/licenses");
            if (response.data.success) { // replace with status check
                let licenseList = response.data.licenses;
                this.setState({
                    licenseList: licenseList
                });
            } else {
                throw response.data.error;
            }
        } catch (error) {
            console.log(error);
        } finally {
            //
        }
    }

    getHardwareItems = async (hardwareId) => {
        try {
            const response = await axios.get(
                "/api/v1/admin/license-test/hardware-items/" + hardwareId,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.state.bearerToken
                    },
                },
            );
            if (response.status === 200 && response.data.success) {
                const { hardwareItems } = response.data;
                this.setState({ hardwareItems: hardwareItems });
            } else {
                throw response.data.error;
            }
        } catch (error) {
            console.warn(error);
        } finally {
            //
        }
    }

    handleDeleteLicense(license) { // TODO replace with async await
        console.log('Deleting ' + license["serialNumber"]);
        axios.delete(
            "/api/v1/admin/license-test/license/" +
            license["licenseId"],
            {
                headers: {
                    Authorization: 'Bearer ' + this.state.bearerToken
                },
            }
        ).then((res) => {
            console.log(res);
        }).catch((error) => {
            console.warn(error);
        }).then(() => {
            this.getLicenses();
        });
    }

    handleNewLicense = async (jsonBody) => {
        var requestBody = {};
        if ("activationDate" in jsonBody) {
            requestBody.when = jsonBody.activationDate;
        } 
        const { what, status } = jsonBody;
        const { productName, major, minor, type } = what;
        const where = this.state.hardwareItems;

        requestBody.what = {
            product: productName, // TODO update API to reflect this name change
            major: major,
            minor: minor,
            build: 99, // TODO this should be optional but some function needs it
            type: type
        };

        requestBody.status = status;
        requestBody.where = where;
        await axios.post(
            "/api/v1/admin/license-test/license",
            requestBody,
            {
                headers: {
                    Authorization: 'Bearer ' + this.state.bearerToken
                },
            },
        );

        await this.getLicenses();
    }

    completeAuthentication(returnData) {
        const bearerToken = returnData.data.token
        const name = returnData.data["userId"];
        Cookies.set("token", bearerToken);
        Cookies.set("name", name);
        this.setState({
            bearerToken: bearerToken,
            authenticated: true,
            name: name
        },
            this.getLicenses
        );
    }

    handleGetHardware(hardwareData) {
        const { hardwareId } = hardwareData;
        Cookies.set("hardwareId", hardwareId);
        const hardwareItems = this.getHardwareItems(hardwareId);
        this.setState({
            hardwareId: hardwareId,
            hardwareItems: hardwareItems
        });
    }

    testBench() {
        if (this.state.authenticated) {
            return (
                <TestBench
                    licenseList={this.state.licenseList}
                    handleDeleteLicense={(license) => this.handleDeleteLicense(license)}
                    handleNewLicense={(jsonBody) => this.handleNewLicense(jsonBody)}
                />
            )
        } else {
            return (
                <Login
                    completeAuthentication={(returnData) => this.completeAuthentication(returnData)}
                    handleGetHardware={(hardwareData) => this.handleGetHardware(hardwareData)}
                />
            )
        }
    }

    logout = async () => {
        Cookies.remove("token");
        Cookies.remove("name");
        this.setState({ authenticated: false });
    }

    render() {
        return (
            <div>
                <div className="ltb-header">
                    <h1 className="ltb-hero">Solutions Link License Test Bench</h1>
                    <h4 className="ltb-welcome">Welcome User {this.state.name}</h4>
                    <h4 className="ltb-version">
                        <a href={this.state.commitUrl}>
                            {this.state.version}
                        </a>
                    </h4> 
                    <div className="ltb-logout">
                        <button className="ltb-button" onClick={() => this.logout()}>Logout</button>
                    </div>
                </div>
                {this.testBench()}  
            </div>
        )
    }
}

class TestBench extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedLicense: {
                serialNumber: "",
                what: {},
                licenseId: 0,
                features: [],
                session: {},
                state: "",
                status: {},
                type: "",
                when: {}
            },
        }
    }

    handleSelectLicense = async (license) => {
        this.setState({ selectedLicense: license });
    }

    handleNewLicense = async (jsonBody) => {
        this.props.handleNewLicense(jsonBody);
    }

    handleDeleteLicense = async (license)  => {
        this.props.handleDeleteLicense(license);
    }

    licenseInfo() {
        const selectedLicense = this.state.selectedLicense;
        return (
            <LicenseInfo
                selectedLicense={selectedLicense}
            />
        )
    }
    
    render() {
        return (
            <div className="ltb-test-bench">

                <div className="ltb-main">
                    <div className="ltb-license-list">
                        <LicenseList
                            licenseList={this.props.licenseList}
                            handleDeleteLicense={
                                (license) => this.handleDeleteLicense(license)
                            }
                            handleNewLicense={
                                (jsonBody) => this.props.handleNewLicense(jsonBody)
                            }
                            handleSelectLicense={
                                (license) => this.handleSelectLicense(license)
                            }
                        />
                    </div>
                    {this.licenseInfo()}
                </div>       
            </div>
        )
    }
}

export default LicenseTestBench;