import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import React from "react";

class UserDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(item) {
    this.setState({
      startDate: item.selection.startDate,
      endDate: item.selection.endDate,
    });
    this.props.startDateAction(
      item.selection.startDate,
      item.selection.endDate
    );
  }

  render() {
    return (
      <DateRangePicker
        onChange={this.onChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={[this.state]}
        direction="horizontal"
      />
    );
  }
}

export default UserDateRange;
