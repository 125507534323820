import React from "react";
import Cookies from "js-cookie";

class LoginRedirect extends React.Component {
  constructor() {
    super();
    this.state = {
      axiosGet: "",
      sfspId: "",
      prot: "",
    };
  }

  componentDidMount() {
    // need to get the ID out of the cookie
    var json = Cookies.getJSON("fawkes");
    console.log("json", json);
    var port = Cookies.get("port");

    let buff = new Buffer(json, "base64");
    let text = buff.toString("ascii");

    var ws = new WebSocket("ws://localhost:" + port + "/SLServer");
    ws.onopen = function () {
      ws.send(text);
    };
    ws.onmessage = function (evt) {
      var received_msg = evt.data;
      console.log("Received", received_msg);
      if (received_msg === "done") {
        window.close();
      }
    };
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <h1>Login Page</h1>
        <p>
          You should be able to login with Twitter, Google, and Facebook from
          this page to get authenticated with the app.
        </p>
      </div>
    );
  }
}
export default LoginRedirect;
