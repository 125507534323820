import React from "react";
import './LicenseTestBench.css';
import Cookies from "js-cookie";
import axios from "axios";

class NewLicense extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            bearerToken: "",
            serialNumber: "",
            hasSession: false,
            setActivationDate: false,
            user: "",
            skuId: 1,
            skus: [],
            type: "advanced",
            activationDate: {
                year: 1970,
                month: 1,
                day: 1,
            },
            status: {
                breakable: true,
                busy: false,
                locked: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const today = new Date();
        const arrayToday = today.toISOString().split('T')[0].split('-');
        this.setState({
            activationDate: {
                year: arrayToday[0],
                month: arrayToday[1],
                day: arrayToday[2],
            }
        })
        const bearerToken = Cookies.get('token');
        this.setState({
            bearerToken: bearerToken,
        });
        this.getSkus(bearerToken);
    }

    getSkus = async (bearerToken) => {
        try {
            const response = await axios.get(
                "/api/v1/admin/license-test/skus",
                {
                    headers: {
                        Authorization: 'Bearer ' + bearerToken
                    },
                },
            );
            if (response.status === 200 && response.data.success) {
                this.setState({
                    skus: response.data.skus
                })
            } else {
                throw response.data.error
            }
        } catch (error) {
            console.warn(error)
        }
    }

    handleChange(event) {
        if (event.target.id === "serialNumber") {
            this.setState({ serialNumber: event.target.value });    
        } else if (event.target.id === "user") {
            this.setState({ user: event.target.value });    
        } else if (event.target.id === "skuId") {
            this.setState({ skuId: event.target.value });
        } else if (event.target.id === "type") {
            this.setState({ type: event.target.value });
        } else if (event.target.id === "licenseState") {
            const statusCase = event.target.value;
            switch (statusCase) {
                case "unlocked":
                    this.setState({
                        status: {
                            locked: false,
                            breakable: true,
                            busy: false,
                        },
                        hasSession: false,
                    });
                    break;
                
                case "autoBreakable":
                    this.setState({
                        status: {
                            locked: true,
                            breakable: true,
                            busy: false,
                        },
                        hasSession: true,
                    });
                    break;
            
                case "manualBreakable":
                    this.setState({
                        status: {
                            locked: true,
                            breakable: true,
                            busy: true,
                        },
                        hasSession: true,
                    });
                    break;
            
                case "notBreakable":
                    this.setState({
                        status: {
                            locked: true,
                            breakable: false,
                            busy: true,
                        },
                        hasSession: true,
                    });
                    break;
                default:
                    this.setState({
                        status: {
                            locked: false,
                            breakable: true,
                            busy: false,
                        },
                        hasSession: false,
                    });
            }
        } else if (event.target.id === "setActivationDate") {
            this.setState({ setActivationDate: true });
        } else if (event.target.id === "activationDate") {
            const dateArray = event.target.value.split('-');
            this.setState({
                activationDate: {
                    year: dateArray[0],
                    month: dateArray[1],
                    day: dateArray[2]
                }
            })
        }
    }

    handleSubmit(event) {
        const status = this.state.status;
        const skuId = this.state.skuId;
        const skus = this.state.skus;
        var skuIndex = 0;
        skus.forEach((sku, index) => {
            if (sku.skuId === parseInt(skuId)) {
                skuIndex = index;
            }
        });
        const what = {
            productName: this.state.skus[skuIndex].productName,
            major: this.state.skus[skuIndex].major,
            minor: this.state.skus[skuIndex].minor,
            type: this.state.type
        }
        var jsonBody = {
            skuId: skuId,
            what: what,
            status: status
        }

        if (this.state.setActivationDate) {
            jsonBody.activationDate = this.state.activationDate; 
        }
        
        event.preventDefault();
        this.props.handleNewLicense(jsonBody);

    }

    activationDatePicker() {
        if (this.state.setActivationDate) {
            const { year, month, day } = this.state.activationDate;
            const today = `${year}-${month}-${day}`
            return (
                <div>
                    <label htmlFor="activationDate">Activation date:</label>
                    <input type="date" id="activationDate" name="activationDate"
                        value={today}
                        min="1970-01-01"
                        max="2099-12-31"
                        onChange={this.handleChange}></input>
                </div>
            )
        }
    }

    render() {

        const skus = this.state.skus;

        let skuList = skus.length > 0
            && skus.map((sku) => {
                return (
                    <option key={sku.skuId} value={sku.skuId}>{sku.productName} {sku.major}.{sku.minor}</option>
                )
            }, this);
        
        return (
            <form onSubmit={this.handleSubmit} className="ltb-create-license-form">
                <label>
                    <b>Product</b>
                    <select id="skuId" value={this.state.value} onChange={this.handleChange}>
                         {skuList}
                    </select>
                    <br /><br />
                    <b>Type</b>
                    <select id="type" value={this.state.value} onChange={this.handleChange}>
                        <option value="advanced">Advanced</option>
                        <option value="premium">Premium</option>
                    </select>
                    <br /><br />
                    <b>License State</b>
                    <select id="licenseState" value={this.state.value} onChange={this.handleChange}>
                        <option value="unlocked">Unlocked</option>
                        <option value="autoBreakable">Locked + Auto Break</option>
                        <option value="manualBreakable">Locked + Manual Break</option>
                        <option value="notBreakable">Locked + Not Breakable</option>
                    </select>
                    <br /><br />
                    <input className=".ltb-checkbox" type="checkbox" id="setActivationDate" onChange={this.handleChange} name="setActivationDate" disabled={!this.state.hasSession} />
                    <label htmlFor="setActivationDate">Custom Activation Date (requires Session)</label>
                    {this.activationDatePicker()}
                </label>
                <br /><br />
                <br /><br />
                <input className=".ltb-button" type="submit" value="Create New License" />
            </form>
        )
    }
}

export default NewLicense;