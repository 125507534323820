import React from "react";
import axios from "axios";
import UniqueDaily from "./UniqueDaily";
import UserDateRange from "./DatePicker";
import DateExtraction from "./DateExtraction";
import { addDays } from "date-fns";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      axiosGet: "",
      statSelect: "",
      separationStudio4Advanced: false,
      separationStudio4Premium: false,
      accuRIPAdvanced: false,
      accuRIPPremium: false,
      uniqueSSAdvancedUsers: "",
      uniqueSSPremiumUsers: "",
      uniqueAccAdvancedUsers: "",
      uniqueAccPremiumUsers: "",
      currentSSAdvancedUsers: "",
      currentSSPremiumUsers: "",
      currentAccAdvancedUsers: "",
      currentAccPremiumUsers: "",
      softwareType: "",
      uniqueViewTest: "",
      showDate: false,
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      totalRow: 0,
      showTotal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.setStartAndEnd = this.setStartAndEnd.bind(this);
    this.currentUsersAxiosPost = this.currentUsersAxiosPost.bind(this);
    this.uniqueUsersAxiosPost = this.uniqueUsersAxiosPost.bind(this);
  }

  componentDidMount() {
    axios.get("/api/v1/telemetry/ping").then((res) => {
      console.log("The res is: ", res);
      var results = res.data.message;
      this.setState({ axiosGet: results });
    });
  }

  currentUsersAxiosPost(address, softwareName) {
    axios.post(address, {}).then((res) => {
      var results = res.data.userNum;
      var newTotal = this.state.totalRow + results;
      console.log("Software Name: ", softwareName);
      this.setState({
        [softwareName]: results,
        totalRow: newTotal,
      });
    });
  }

  uniqueUsersAxiosPost(address, softwareName) {
    axios
      .post(address, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then((res) => {
        var results = res.data.userNum;
        var newTotal = this.state.totalRow + results;
        this.setState({
          [softwareName]: results,
          totalRow: newTotal,
        });
      });
  }

  handleSubmit(event) {
    this.setState({ totalRow: 0 });

    if (this.state.statSelect === "currentActiveUsers") {
      if (this.state.separationStudio4Advanced) {
        this.currentUsersAxiosPost(
          "/api/v1/statistics/getCurrentActiveSepStudioAdvancedUsers",
          "currentSSAdvancedUsers"
        );
      } else {
        this.setState({ currentSSAdvancedUsers: "" });
      }
      if (this.state.separationStudio4Premium) {
        this.currentUsersAxiosPost(
          "/api/v1/statistics/getCurrentActiveSepStudioPremiumUsers",
          "currentSSPremiumUsers"
        );
      } else {
        this.setState({ currentSSPremiumUsers: "" });
      }
      if (this.state.accuRIPAdvanced) {
        this.currentUsersAxiosPost(
          "/api/v1/statistics/getCurrentActiveAccuripAdvancedUsers",
          "currentAccAdvancedUsers"
        );
      } else {
        this.setState({ currentAccAdvancedUsers: "" });
      }
      if (this.state.accuRIPPremium) {
        this.currentUsersAxiosPost(
          "/api/v1/statistics/getCurrentActiveAccuripPremiumUsers",
          "currentAccPremiumUsers"
        );
      } else {
        this.setState({ currentAccPremiumUsers: "" });
      }
    } else if (this.state.statSelect === "uniqueDailyUsers") {
      if (this.state.separationStudio4Advanced) {
        this.uniqueUsersAxiosPost(
          "/api/v1/statistics/getDailySepStudioAdvancedUsers",
          "uniqueSSAdvancedUsers"
        );
      } else {
        this.setState({ uniqueSSAdvancedUsers: "" });
      }
      if (this.state.separationStudio4Premium) {
        this.uniqueUsersAxiosPost(
          "/api/v1/statistics/getDailySepStudioPremiumUsers",
          "uniqueSSPremiumUsers"
        );
      } else {
        this.setState({ uniqueSSPremiumUsers: "" });
      }
      if (this.state.accuRIPAdvanced) {
        this.uniqueUsersAxiosPost(
          "/api/v1/statistics/getDailyAccuripAdvancedUsers",
          "uniqueAccAdvancedUsers"
        );
      } else {
        this.setState({ uniqueAccAdvancedUsers: "" });
      }
      if (this.state.accuRIPPremium) {
        this.uniqueUsersAxiosPost(
          "/api/v1/statistics/getDailyAccuripPremiumUsers",
          "uniqueAccPremiumUsers"
        );
      } else {
        this.setState({ uniqueAccPremiumUsers: "" });
      }
    } else if (this.state.statSelect === "totalUsers") {
      console.log("TOTAL USERS HERE");
    } else if (this.state.statSelect === "showAllStats") {
      console.log("SHOW ALL STATS");
    }
    this.setState({ totalUsers: 0 });
    event.preventDefault();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (value === "uniqueDailyUsers") {
      this.setState({
        showDate: true,
      });
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        showDate: false,
      });
      this.setState({
        [name]: value,
      });
    }
    this.setState({ currentSSAdvancedUsers: "" });
    this.setState({ currentSSPremiumUsers: "" });
    this.setState({ currentAccAdvancedUsers: "" });
    this.setState({ currentAccPremiumUsers: "" });
    this.setState({ uniqueSSAdvancedUsers: "" });
    this.setState({ uniqueSSPremiumUsers: "" });
    this.setState({ uniqueAccAdvancedUsers: "" });
    this.setState({ uniqueAccPremiumUsers: "" });
    this.setState({ startDate: new Date() });
    this.setState({ endDate: addDays(new Date(), 7) });
  }

  handleCheckbox(event) {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  setStartAndEnd(startDate, endDate) {
    this.setState({
      startDate: DateExtraction(startDate),
      endDate: DateExtraction(endDate),
    });
  }

  render() {
    var dateShow = "";
    if (this.state.showDate === true) {
      dateShow = <UserDateRange startDateAction={this.setStartAndEnd} />;
    }

    var checkboxNum = 0;
    var uniqueView = "";
    if (this.state.uniqueSSAdvancedUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"uniqueSSAdvancedUsers"}
          totalUsers={this.state.uniqueSSAdvancedUsers}
          headerValue={"Unique Daily Separation Studio 4 Advanced Users"}
        />,
      ];
    }
    if (this.state.uniqueSSPremiumUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"uniqueSSPremiumUsers"}
          totalUsers={this.state.uniqueSSPremiumUsers}
          headerValue={"Unique Daily Separation Studio 4 Premium Users"}
        />,
      ];
    }
    if (this.state.uniqueAccAdvancedUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"uniqueAccAdvancedUsers"}
          totalUsers={this.state.uniqueAccAdvancedUsers}
          headerValue={"Unique Daily AccuRIP Advanced Users"}
        />,
      ];
    }
    if (this.state.uniqueAccPremiumUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"uniqueAccPremiumUsers"}
          totalUsers={this.state.uniqueAccPremiumUsers}
          headerValue={"Unique Daily AccuRIP Premium Users"}
        />,
      ];
    }

    if (this.state.currentSSAdvancedUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"currentSSAdvancedUsers"}
          totalUsers={this.state.currentSSAdvancedUsers}
          headerValue={"Current Separation Studio 4 Advanced Users"}
        />,
      ];
    }
    if (this.state.currentSSPremiumUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"currentSSPremiumUsers"}
          totalUsers={this.state.currentSSPremiumUsers}
          headerValue={"Current Separation Studio 4 Premium Users"}
        />,
      ];
    }
    if (this.state.currentAccAdvancedUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"currentAccAdvancedUsers"}
          totalUsers={this.state.currentAccAdvancedUsers}
          headerValue={"Current AccuRIP Advanced Users"}
        />,
      ];
    }
    if (this.state.currentAccPremiumUsers !== "") {
      checkboxNum += 1;
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"currentAccPremiumUsers"}
          totalUsers={this.state.currentAccPremiumUsers}
          headerValue={"Current AccuRIP Premium Users"}
        />,
      ];
    }

    if (checkboxNum > 1) {
      uniqueView = [
        uniqueView,
        <UniqueDaily
          key={"totalRow"}
          totalUsers={this.state.totalRow}
          headerValue={"Total"}
        />,
      ];
    }

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <h1>Solutions Link Tool</h1>
        </div>
        <div className="grid-container">
          <div className="item2">
            <h3>Select Statistic</h3>
            <select
              name="statSelect"
              onChange={this.handleChange}
              value={this.state.statSelect}
            >
              <option value=""></option>
              <option value="currentActiveUsers">Current Active Users</option>
              <option value="uniqueDailyUsers">Unique Daily Users</option>
              <option value="totalUsers">Total Users</option>
              <option value="showAllStats">Show All Stats</option>
            </select>
            <p></p>
            <h5>Separation Studio 4 Advanced 2020</h5>
            <input
              type="checkbox"
              name="separationStudio4Advanced"
              checked={this.state.separationStudio4Advanced}
              onChange={this.handleCheckbox}
            ></input>
            <div> </div>
            <h5>Separation Studio 4 Premium</h5>
            <input
              type="checkbox"
              name="separationStudio4Premium"
              checked={this.state.separationStudio4Premium}
              onChange={this.handleCheckbox}
            ></input>
            <div> </div>
            <h5>AccuRIP Advanced 2020</h5>
            <input
              type="checkbox"
              name="accuRIPAdvanced"
              checked={this.state.accuRIPAdvanced}
              onChange={this.handleCheckbox}
            ></input>
            <div> </div>
            <h5>AccuRIP Premium</h5>
            <input
              type="checkbox"
              name="accuRIPPremium"
              checked={this.state.accuRIPPremium}
              onChange={this.handleCheckbox}
            ></input>
            <div>
              <form onSubmit={this.handleSubmit}>
                <input type="submit" value="submit"></input>
              </form>
            </div>
          </div>
          <div className="item3">{uniqueView}</div>
        </div>
        {dateShow}
      </div>
    );
  }
}

export default Home;
