function DateExtraction (date) {
    var year = "";
    var month = "";
    var day = "";

    var regex = /[A-z]{3}.\d{2}.\d{4}/g;
    var newDate = regex.exec(date);
    newDate = newDate.toString()

    if (newDate.includes('Jan')) {
        month = "01"
    } else if (newDate.includes('Feb')) {
        month = "02"
    } else if (newDate.includes('Mar')) {
        month = "03"
    } else if (newDate.includes('Apr')) {
        month = "04"
    } else if (newDate.includes('May')) {
        month = "05"
    } else if (newDate.includes('Jun')) {
        month = "06"
    } else if (newDate.includes('Jul')) {
        month = "07"
    } else if (newDate.includes('Aug')) {
        month = "08"
    } else if (newDate.includes('Sep')) {
        month = "09"
    } else if (newDate.includes('Oct')) {
        month = "10"
    } else if (newDate.includes('Nov')) {
        month = "11"
    } else if (newDate.includes('Dec')) {
        month = "12"
    }

    year = newDate.slice(7,11)
    day = newDate.slice(4,6)

    
    newDate = (year + '-' + month + '-' + day + " 00:00:00");
    return (newDate);
}

export default DateExtraction;