import React from "react";
import './LicenseTestBench.css';

class LicenseInfo extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
        };

    }
    
    serialNumber() {
        return (
            <h2>{this.props.selectedLicense.serialNumber}</h2>
        );
    }

    what() {
        const what = this.props.selectedLicense.what;
        if ("product" in what && "major" in what && "minor" in what) {
            var { product, major, minor } = what;
            var type = this.props.selectedLicense.type;
            const typeCap = type.charAt(0).toUpperCase() + type.slice(1);
            return (
                <div>
                    <h3>{product} {major}.{minor} ({typeCap})</h3>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }   
    }

    when() {
        const when = this.props.selectedLicense.when;
        var activated = "";
        var expires = "";
        ("activated" in when) ? activated = when.activated : activated = "N/A";
        ("expires" in when) ? expires = when.expires : expires = "N/A";

        if ("activated" in when || "expires" in when) {
            return (
                <div>
                    <b>Activated:</b> {activated}
                    <br />
                    <b>Expires:</b> {expires}
                </div>
            )
        }
    }

    parseStatusMessage(status) {
        if ("locked" in status && "busy" in status && "breakable" in status) {
            var { locked, busy, breakable } = status;
            var statusMessage = "";
            var confluenceUrl = "https://fawkesengineering.atlassian.net/wiki/spaces/SFSP/pages/";
            if (!locked) { 
                statusMessage = "This License is Unlocked. You can start a Session using this License."
                confluenceUrl = confluenceUrl + "1139277891";
            } else if (breakable) {
                if (!busy) {
                    statusMessage = "This License is Auto Breakable. It has an Inactive Session, so you may Auto Break this License's other Session."
                    confluenceUrl = confluenceUrl + "1139212330";
                } else {
                    statusMessage = "This License is Manual Breakable. It is Busy in another Session. You are the Owner, so you may Manual Break the License's activation."
                    confluenceUrl = confluenceUrl + "1139146825";
                }
            } else {
                statusMessage = "This License is Unbreakable. The License is busy in another Session and you are not the Owner."
                confluenceUrl = confluenceUrl + "1139638291";
            }
            return { statusMessage, confluenceUrl };
        }
    }

    status() {
        const status = this.props.selectedLicense.status;
        if ("locked" in status && "busy" in status && "breakable" in status) {            
            const { statusMessage, confluenceUrl } = this.parseStatusMessage(status);

            return (
                <div className="ltb-license-info-item">
                    <h3>Status</h3>
                    {statusMessage}
                    <br />
                    <a
                        href={confluenceUrl}
                    >
                        Open this Status in Confluence
                    </a>
                </div>
            )

        }
    }

    session() {
        const session = this.props.selectedLicense.session;
        if ("who" in session && "where" in session && "when" in session) {
            var { who, where, when } = session;
            return (
                <div className="ltb-license-info-item">
                    <h3>Session Info</h3>
                    <h4>Where</h4>
                    <div>
                        <b>User:</b> {where.user}
                        <br />
                        <b>Computer Name:</b> {where.computerName}
                        <br />
                        <b>Computer Model:</b> {where.computerModel} ({where.operatingSystem})
                        <br />
                        <b>Serial Number:</b> {where.serialNumber}
                    </div>
                    <h4>Who</h4>
                    {who.email}
                    <h4>When</h4>
                    <b>Activated:</b> {when.activated}
                    <br />
                    <b>Expires:</b> {when.expires}
                </div> 
            )

        }
    }

    features() {
        const features = this.props.selectedLicense.features;
        if (features.length > 0) {
            const featureList = features.map((feature, index) => 
                <div key={index.toString()} className="ltb-license-info-item">
                    <h4>{feature.serialNumber}</h4>
                    <h4>{feature.what.product} {feature.what.major}.{feature.what.minor}</h4>
                    <b>State:</b> {feature.state}
                    <br />
                    <b>Type:</b> {feature.type}
                    <br />
                    <br />
                    <b>Status</b>
                    <br />
                    {this.parseStatusMessage(feature.status).statusMessage}
                    <br />
                    <a
                        href={this.parseStatusMessage(feature.status).confluenceUrl}
                    >
                        Open this Status in Confluence
                    </a>
                </div>
            );
            return (
                <div className="ltb-license-info-item">
                    <h3>Features</h3>
                    <div>{featureList}</div>
                </div>
            )
        }
    }

    render() {        
        return (
            <div className="ltb-license-control">
                {this.serialNumber()}
                {this.what()}
                {this.when()}
                {this.status()}
                {this.session()}
                {this.features()}
            </div>
        )
    }
}

export default LicenseInfo;