import React from "react";
import axios from "axios";

import HttpError from './HttpError';
import LoadingSpinner from './LoadingSpinner';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            serialNumber: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        if (event.target.id === "username") {
            this.setState({ username: event.target.value });    
        } else if (event.target.id === "password") {
            this.setState({ password: event.target.value });    
        } else if (event.target.id === "serialNumber") {
            this.setState({ serialNumber: event.target.value });    
        }
    }

    handleSubmit(event) {
        const credentials = {
            username: this.state.username,
            password: this.state.password,
        }

        const serialNumber = this.state.serialNumber

        event.preventDefault();
        this.props.handleLogin(credentials, serialNumber);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    <b>Email Address: </b>
                    <input
                        type="text"
                        id="username"
                        onChange={this.handleChange}
                        placeholder="Email Address"
                    />
                    <br />
                    <b>Password: </b>
                    <input
                        type="password"
                        id="password"
                        onChange={this.handleChange}
                        placeholder="Password"
                    />
                    <br />
                    <b>Computer Serial Number: </b>
                    <input
                        type="text"
                        id="serialNumber"
                        onChange={this.handleChange}
                        placeholder="Serial Number"
                    />
                </label>
                <br />
                <input type="submit" value="Log in" />
            </form>
        )
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            authenticated: false,
            username: "",
            password: "",
            loading: false,
            loginError: {},
            bearerToken: "",
        }
    }
    
    handleLogin = async (credentials, serialNumber) => {
        const username = credentials.username;
        const password = credentials.password;

        this.setState({ loading: true });
        const response = await axios.put("/api/v1/login/login", {
            "userName": username,
            "password": password
        });
        this.setState({ loading: false });
        
        if (response.data.success) {
            const bearerToken = response.data.token;
            this.setState({
                authenticated: true,
                loginError: {},
                bearerToken: bearerToken
            });
            this.props.completeAuthentication(response);
            this.handleGetHardware(serialNumber, bearerToken);
        } else {
            this.setState({
                authenticated: false,
                loginError: {
                    message: response.data.message,
                    status: response.status
                }
            })
            console.warn(this.state.loginError);
        };
    };

    handleGetHardware = async (serialNumber, bearerToken) => {
        try {
            const response = await axios.get(
                "/api/v1/admin/license-test/hardware-id/?serialNumber=" + encodeURIComponent(serialNumber),
                {
                    headers: {
                        Authorization: 'Bearer ' + bearerToken
                    },
                },
            );

            if (response.status === 200 && response.data.success) {
                const { hardwareId } = response.data;
                this.props.handleGetHardware({
                    hardwareId: hardwareId,
                });
            }
        } catch (error) {
            console.warn(error);
        }
    }

    loginForm() {
        if (this.state.loading) {
            return (
                <LoadingSpinner />
            )
        } else {
            const loginError = this.state.loginError;
            if (Object.keys(loginError).length === 0 && loginError.constructor === Object) {
                return (
                    <div>
                        <LoginForm
                            handleLogin={(credentials, serialNumber) => this.handleLogin(credentials, serialNumber)}
                        />
                    </div>
                )
            } else {
                return (
                    <div>
                        <HttpError
                            error={loginError}
                        />
                        <LoginForm
                            handleLogin={(credentials, serialNumber) => this.handleLogin(credentials, serialNumber)}  
                        />
                    </div>
                )
            }
        }
    }
    
    render() {
        return (
            <div className="ltb-login">
                <div className="ltb-login-form">
                    {this.loginForm()}
                </div>
            </div>
        )
    }
}

export default Login;