import React from "react";
import './LicenseTestBench.css';
import NewLicense from './NewLicense.js';


class LicenseList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newLicense: false,
            selectedLicense: {}
        }
    }
    
    handleNewLicense = async (jsonBody) => {
        this.setState({
            newLicense: false
        });
        this.props.handleNewLicense(jsonBody);
    }
    
    
    onNewLicense = async () => {
        this.setState({
            newLicense: true
        });
    }
    handleSelectLicense(license) {
        this.props.handleSelectLicense(license);
        this.setState({
            selectedLicense: license
        });
    }

    licenseStyle(license) {
        var licenseStyle = {};
        if (this.state.selectedLicense.licenseId === license.licenseId) {
            licenseStyle = {
                backgroundColor: "#d8d8d8",
                boxShadow: "1px 2px"
            }
        }
        return licenseStyle;
    }

    licenseListView() {
        if (this.state.newLicense) {
            return (
                <NewLicense
                    handleNewLicense={(jsonBody) => this.handleNewLicense(jsonBody)}
                />
            )
        } else {
            const licenseList = this.props.licenseList;
            return licenseList.map((license) =>
                <License
                    license={license}
                    selectedLicense={this.state.selectedLicense}
                    key={license["serialNumber"]}
                    handleDeleteLicense={
                        (license) => this.props.handleDeleteLicense(license)
                    }
                    handleSelectLicense={(license) => {
                        this.handleSelectLicense(license)
                    }}
                    licenseStyle={this.licenseStyle(license)}
                />
            )
        }
    }
    render() {
        return (
            <div>
                <div className="ltb-license-list-container">
                    <h2>Licenses</h2>
                    <button
                        className="ltb-button"
                        onClick={() => this.onNewLicense()}
                    >
                        New
                    </button>
                </div>
                <div>
                    {this.licenseListView()}
                </div>
            </div>
        )
    }
}

class License extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    deleteButton() {
        return (
            <button className="ltb-btn-delete ltb-button" onClick={
                () => this.props.handleDeleteLicense(this.props.license)}>
                Delete
            </button>
        )
    }

    typeButton() {
        return (
            <button className="ltb-btn-type ltb-button">
                {this.props.license.type.charAt(0).toUpperCase() + this.props.license.type.slice(1)}
            </button>
        )
    }

    caseButton() {
        var caseText = '';
        var caseStyle = {};
        if (!this.props.license.status.locked) {
            caseText = 'Unlocked';
            caseStyle = {backgroundColor: '#ACECD5'}
        } else if (this.props.license.status.breakable) {
            if (this.props.license.status.busy) {
                caseText = 'Manual Breakable'
                caseStyle = {backgroundColor: '#FFD5B8'}
            } else {
                caseText = 'Auto Breakable'
                caseStyle = {backgroundColor: '#FFF9AA'}
            }
        } else {
            caseText = 'Unbreakable';
            caseStyle = {backgroundColor: '#FFB9B3'}
        }
        return (
            <button
                className="ltb-btn-case ltb-button"
                style={caseStyle}
            >
                {caseText}
            </button>
        )
    }

    handleSelectLicense() {
        this.props.handleSelectLicense(this.props.license);
    }

    render() {
        const license = this.props.license;
        var serialNumber = license["serialNumber"];
        serialNumber = serialNumber.slice(0, 38) + '\t...';
        return (
            <div key={serialNumber}>
                <div 
                className="ltb-license-list-item"
                style={this.props.licenseStyle}
                key={serialNumber}
                onClick={() => this.handleSelectLicense()}
                >
                    <div className="ltb-license-list-serial">
                        {serialNumber}
                    </div>
                    {this.typeButton()}
                    {this.caseButton()}
                    {this.deleteButton()}
                </div>
            </div>
        )

    }
}


export default LicenseList;