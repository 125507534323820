import React from "react";

class LoadingSpinner extends React.Component { // Move to new file
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (<div className="loader"></div>);
    }

}

export default LoadingSpinner;