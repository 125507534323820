import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "./Home.js";
import Login from "./Login.js";
import LoginRedirect from "./LoginRedirect";
import LicenseTestBench from "./LicenseTestBench/LicenseTestBench";

class App extends Component {
  render() {
    return (
      <div className="container">
        <BrowserRouter>
          <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/loginredirect" component={LoginRedirect} />
            <Route exact path="/license-test-bench" component={LicenseTestBench} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
